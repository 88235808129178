.winner-odds {
  width: 176px;
  border: 2px solid #0000;
  background: 
   linear-gradient(grey 0 0) padding-box,
   repeating-conic-gradient(green 0 25%, lightGrey 0 50%) border-box;
}

.loser-odds {
  width: 176px;
  border: 2px solid #0000;
  background: 
   linear-gradient(grey 0 0) padding-box,
   repeating-conic-gradient(red 0 25%,lightGrey 0 50%) border-box;
}

.non-final-odds {
  width: 176px;
  border: 2px solid #0000;
  background: 
   linear-gradient(grey 0 0) padding-box,
   repeating-conic-gradient(lightGrey 0 25%, lightGrey 0 50%) border-box;
}